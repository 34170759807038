<template>
  <div
    class="video-card"
    @click="handleClick"
  >
    <div class="cover-container">
      <lazy-img
        class="cover"
        :src="thumbnail"
        alt=""
      ></lazy-img>
      <span class="duration">{{formateDuration(video.duration)}}</span>
    </div>

    <div class="title">{{ video.title }}</div>
    <div class="time">{{ formateDateStr(video.timestamp) }}</div>
  </div>
</template>

<script>
// TODO 封面图懒加载
import {
  formateDateStr,
  getYoutubeVideoId,
  formateDuration,
} from '@/assets/js/util';
import LazyImg from '@/components/common/LazyImg/index.vue';

export default {
  props: {
    info: {
      type: Object,
      required: true,
    },
    // replaceHistory: {
    //   type: Boolean,
    //   default: false
    // }
  },
  computed: {
    video() {
      return this.info;
    },
    thumbnail() {
      return this.video.thumbnail && this.video.thumbnail.replace('/sddefault.', '/mqdefault.')
    }
  },
  methods: {
    formateDateStr,
    formateDuration,
    handleClick() {
      this.$emit('click');
      // this.$router.push({
      //   name: 'Detail',
      //   params: {
      //     url: this.video.embedUrl
      //   }
      // });
      const videoId = getYoutubeVideoId(this.video.origin);
      const route = {
        path: '/detail',
        query: {
          id: videoId,
          title: this.video.title,
          updateTime: this.video.timestamp,
          viewCount: this.video.view_count,
        },
      };
      // 详情页点击应该是replace
      // this.replaceHistory ? this.$router.replace(route) : this.$router.push(route);
      this.$router.push(route);
    },
  },
  components: {
    LazyImg,
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/style/func.scss';
@import '@/assets/style/mixins.scss';
.video-card {
  background: #fff;
  padding: px2vw(36) px2vw(16) px2vw(40) px2vw(16);
  .cover-container {
    position: relative;
    width: 100%;
    // height: 53.33vw;
    background: #eee;
    .duration {
      position: absolute;
      bottom: 0;
      right: 0;
      margin: 5px;
      padding: 1px 4px;
      -webkit-border-radius: 2px;
      border-radius: 2px;
      font-size: px2vw(24);
      color: #eeeeee;
      background-color: hsla(0, 0%, 6.7%, 0.8);
    }
  }
  .cover {
    width: 100%;
    // height: inherit;
    height: px2vw(403);
  }
  .title {
    font-size: px2vw(32);
    color: #333;
    margin-top: px2vw(26);
    margin-bottom: px2vw(22);
    @include text-overflow();
  }
  .time {
    font-size: px2vw(25);
    color: #a8a8a8;
  }
}
</style>
