import { enhandcedLocalstorage } from './fe-common-utils';
import {logger} from './logger';
import { getRandomItems } from './project-utils';

export const persistLayer = {
  cache: {},
  save(key, value) {
    persistLayer.cache[key] = value; // localstorage不可用时，在不刷新页面情况下保证可用
    try {
      return enhandcedLocalstorage.setItem(key, value); // 刷新页面时获取数据
    } catch (e) {
      logger.error(e);
    }
  },
  remove(key) {
    delete persistLayer.cache[key];
    try {
      return enhandcedLocalstorage.removeItem(key);
    } catch (e) {
      logger.error(e);
    }
  },
  retrive(key) {
    if (typeof persistLayer.cache[key] != 'undefined') {
      return persistLayer.cache[key];
    }
    try {
      return enhandcedLocalstorage.getItem(key);
    } catch (e) {
      logger.error(e);
    }
  }
}

persistLayer.keys = {
  'feedItems': 'feedItems'
}

/**
 * 保存feed数据，最多30条，去重
 * @param {*} items 
 * @param {*} uniqueKey 
 */
persistLayer.saveFeedItems = function(items, uniqueKey) {
  const key = persistLayer.keys.feedItems;
  let exist = persistLayer.retrive(key) || [];
  const maxLength = 30; // 最多保留30条

  if (uniqueKey) {
    const existKeys = exist.map(item => item[uniqueKey])
    items.forEach(item => {
      if (!existKeys.includes(item[uniqueKey])) {
        exist.push(item);
      }
    })
  } else {
    logger.warn('去重需要提供uniqueKey')
    exist.push(...items);
  }
  
  if (exist.length > maxLength) {
    exist = exist.slice(exist.length - 30);
  }
  persistLayer.save(key, exist);
}

/**
 * 随机取3条数据
 * @param {*} count 
 */
persistLayer.retriveFeedItems = function(count, predicate) {
  if (typeof count === 'function') {
    predicate = count;
    count = 3;
  }
  let list = persistLayer.retrive(persistLayer.keys.feedItems) || [];
  if (typeof predicate === 'function') {
    list = list.filter(item => predicate(item));
  }
  return getRandomItems(list, count);
}