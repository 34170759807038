import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      keepAlive: true //需要被缓存
    }
  },
  {
    path: '/detail',
    name: 'Detail',
    // props: true,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "detail" */ '../views/Detail.vue') // TODO 优化
  }
];

const router = new VueRouter({
  routes
});

export default router;
