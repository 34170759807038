import axios from './axios';
// import { Env } from '@/assets/js/util.js';
// import { CHANNEL_MAP } from '@/assets/js/constants.js';

/**
 * 拉取tab分类列表
 * @param {*} country
 */
export function queryFeedTabs(country = 'US') {
  // if (Env.isDev()) {
  //   const json = require('../../../mock/tab.json');
  //   return Promise.resolve({data: json})
  // }
  return axios.get(`/v1/feed?country=${country}`);
}

/**
 * 拉取feed流数据列表
 * @param {*} category
 * @param {*} param1
 */
export function queryFeedList(category = 'all', { country = 'US', next }) {
  // if (Env.isDev()) {
  //   const json = require('../../../mock/feed-all.json');
  //   return Promise.resolve({ data: json });
  // }
  return axios.get(`/v1/feed/${category}`, {
    params: {
      country,
      next: next ? next : undefined
    }
  });
}

/**
 *
 * @param {Object} data
 * @param {String} data.channel
 */
// export function queryGameList(data) {
//   // console.log(data, data.channel, mockPool, mockPool[data.channel]);
//   return Promise.resolve(
//     mockPool[data.channel].map(item => ({
//       ...item,
//       videoId: getVideoId(item.watchUrl),
//       embedUrl: `https://www.youtube.com/embed/${getVideoId(item.watchUrl)}`
//     }))
//   );
// }

// https://www.youtube.com/feed/trending?persist_gl=1&gl=US
// https://www.youtube.com/feed/trending?persist_gl=1&gl=JP
// const mockList = [
//   // NFL播放不了
//   // {
//   //   title: 'The Weeknd’s FULL Pepsi Super Bowl LV Halftime Show',
//   //   watchUrl: '/watch?v=9rhadTURsrw',
//   //   updateTime: '5小时前', // TODO 时间字段需要确认一下是否需要，或者爬的时候做好数据计算
//   //   coverImg:
//   //     'https://i.ytimg.com/vi/9rhadTURsrw/hq720.jpg?sqp=-oaymwEhCK4FEIIDSFryq4qpAxMIARUAAAAAGAElAADIQj0AgKJD&rs=AOn4CLBCMlT6R5h3-3xPl8TwCFzEKjRNYA',
//   //   duration: '14:11'
//   // },
//   // {
//   //   title: 'Chiefs vs. Buccaneers | Super Bowl LV Game Highlights',
//   //   watchUrl: '/watch?v=Gz5-NQROQGw',
//   //   updateTime: '2021年2月8日',
//   //   coverImg: 'https://i.ytimg.com/vi/Gz5-NQROQGw/hqdefault.jpg?sqp=-oaymwEcCNACELwBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLAmQlYOZMshIAR_3Ls9-axFUELxcA',
//   //   duration: '12:11'
//   // },
//   {
//     title:
//       'Adam Levine Sets Up Gwen Stefani & Blake Shelton | T-Mobile Big Game 2021 Commercial',
//     watchUrl: '/watch?v=HxllNfjYw2U',
//     updateTime: '2021年2月8日',
//     coverImg:
//       'https://i.ytimg.com/vi/HxllNfjYw2U/hqdefault.jpg?sqp=-oaymwEcCNACELwBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLAZVDhQTonpC8XzSeUQQXkk8LW5LQ',
//     duration: '1:01'
//   },
//   {
//     title: "World's Longest Field Goal- Robot vs NFL Kicker",
//     watchUrl: '/watch?v=P_6my53IlxY',
//     updateTime: '1天前',
//     coverImg:
//       'https://i.ytimg.com/vi/IWBsDaFWyTE/hq720.jpg?sqp=-oaymwEhCK4FEIIDSFryq4qpAxMIARUAAAAAGAElAADIQj0AgKJD&rs=AOn4CLCEqlK_1vp8wnWXLTMI84ZUPUg7xQ',
//     duration: '2:00'
//   },
//   {
//     title: 'All-Electric Cadillac LYRIQ | ScissorHandsFree | Full Version',
//     watchUrl: '/watch?v=0KAlqthD6Gc',
//     updateTime: '15小时前',
//     coverImg:
//       'https://i.ytimg.com/vi/0KAlqthD6Gc/hqdefault.jpg?sqp=-oaymwEcCNACELwBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLBpsPLeQNRna3dhDdw7-7sKFBt65Q',
//     duration: '12:11'
//   },
//   {
//     title: 'Loss',
//     watchUrl: '/watch?v=_OVVgSOaiUE',
//     updateTime: '15小时前',
//     coverImg:
//       'https://i.ytimg.com/vi/P_6my53IlxY/hq720.jpg?sqp=-oaymwEhCK4FEIIDSFryq4qpAxMIARUAAAAAGAElAADIQj0AgKJD&rs=AOn4CLCpjYseRBQVUAu3eP7E5sBdtbkA_A',
//     duration: '21:24'
//   }
// ];

// const mockPool = {
//   [CHANNEL_MAP.all]: [...mockList],
//   [CHANNEL_MAP.game]: [...mockList.slice(0, 1)],
//   [CHANNEL_MAP.movie]: [...mockList.slice(0, 2)],
//   [CHANNEL_MAP.music]: [...mockList.slice(0, 1)],
//   [CHANNEL_MAP.news]: [...mockList.slice(0, 1)]
// };
