<template>
  <div class="feed-item" v-if="FEED_ITEM_TYPE_TO_COMPONENTS[type]">
    <component
      :is="FEED_ITEM_TYPE_TO_COMPONENTS[type]"
      v-bind="$attrs"
      @click="$emit('click')"
    ></component>
  </div>
</template>

<script>
import Afc from './AFC';
import VideoCard from './VideoCard';
import { FEED_ITEM_TYPE_TO_COMPONENTS } from '@/assets/js/constants.js';

export default {
  props: {
    type: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      FEED_ITEM_TYPE_TO_COMPONENTS
    };
  },
  components: {
    VideoCard,
    Afc
  }
};
</script>

<style></style>
