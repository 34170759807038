<template>
  <div class="loading">
    <img
      class="icon"
      src="../assets/images/loading@2x.png"
      alt="loading.png"
    />
    <span class="text">
      <span>&ensp;loading</span>
      <span class="dot">...</span>
    </span>
  </div>
</template>

<script>
export default {
  props: {
    // visible
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
@import '@/assets/style/func.scss';
.loading {
  padding: px2vw(50) 0;
  text-align: center;
  color: #999999;
  line-height: px2vw(32);
  .icon {
    width: px2vw(38);
    height: px2vw(38);
    animation: rotateLoading ease-out 1.5s infinite;
  }
  .text {
    vertical-align: text-top;
  }
}

@keyframes rotateLoading {
  0% {
    -webkit-transform: rotateZ(0);
    transform: rotateZ(0);
  }
  100% {
    -webkit-transform: rotateZ(360deg);
    transform: rotateZ(360deg);
  }
}
</style>
