import { Types } from './fe-common-utils';
export default function ensureIntersectionObserverCtor() {
  return new Promise((resolve, reject) => {
    if (Types.isFunction(window.IntersectionObserver)) {
      resolve(window.IntersectionObserver)
    } else {
      import(/* webpackChunkName: "IntersectionObserver-polyfill" */ 'intersection-observer')
        .then(() => {
          resolve(window.IntersectionObserver) // polyfilled
        })
        .catch(err => {
          reject(err)
        })
      // reject(new Error('not support IntersectionObserver API'))
    }
  })
}
