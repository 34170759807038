<template>
<div :class="['lazy-img', loaded ? 'loaded' : '', intersected ? 'intersected' : '']" ref="wrapper" :data-src="src">
  <slot v-if="showRealImg">
    <img class="img" :src="src" v-bind="$attrs">
  </slot>
  <slot v-else name="placeholder">
    <div class="img-placeholder" v-bind="$attrs"></div>
  </slot>
</div>
</template>

<script>
import { ensureIntersectionObserverCtor } from '@/assets/js/util'
export default {
  props: {
    src: {
      type: String,
      required: true,
      default: ''
    },
    intersectionOptions: {
      type: Object,
      default: () => ({
        root: null,
        threshold: 0.1
      })
    },
    isObserve: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      observer: null,
      intersected: false,
      loaded: false
    }
  },
  mounted() {
    if (!this.isObserve) {
      this.loadImg()
      this.intersected = true
      return
    }
    // console.log('lazyimg: ensureIntersectionObserverCtor')
    ensureIntersectionObserverCtor().then(IntersectionObserver => {
      this.observer = new IntersectionObserver(entries => {
        const entry = entries[0]
        if (entry.isIntersecting) {
          this.handleIntersected()
        }
      }, this.intersectionOptions)

      this.observer.observe(this.$refs.wrapper)
    }).catch(() => {
      // console.error(err)
      this.handleIntersected()
    })
  },
  destroyed() {
    this.observer && this.observer.disconnect()
  },
  computed: {
    showRealImg() {
      return this.intersected && this.loaded
    }
  },
  methods: {
    handleIntersected() {
      this.intersected = true
      this.observer && this.observer.disconnect()
      this.$emit('intersect')
      this.loadImg()
    },
    loadImg() {
      let image = new Image()
      image.onload = () => {
        this.loaded = true
      }
      image.src = this.src
    }
  }
}
</script>

<style lang="scss" scoped>
.img {
  width: inherit;
  height: inherit;
}
.img-placeholder {
 background: #f3f4f6;
 width: 100%;
 height: 100%;
}
</style>
