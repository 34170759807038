/**
 * 页面在Y方向的滚动距离
 */
export function getScrollTop(element = window) {
  if (element === window) {
    return Math.max(
      window.pageYOffset || 0,
      document.documentElement.scrollTop
    );
  }

  return element.scrollTop;
}
/**
 * $.offset https://api.jquery.com/offset/
 * http://youmightnotneedjquery.com/
 * @param {*} ele
 */
export function getElementOffset(el) {
  var rect = el.getBoundingClientRect();

  return {
    top: rect.top + document.documentElement.scrollTop,
    left: rect.left + document.documentElement.scrollLeft
  };
}

// export const on = (function() {
//   if (document.addEventListener) {
//     return function on(ele, event, handler) {
//       if (ele && event && handler) {
//         ele.addEventListener(event, handler, false)
//       }
//     }
//   } else if (document.detachEvent) {
//     return function on(ele, event, handler) {
//       ele.attachEvent('on' + event, handler)
//     }
//   }
// })()
export const on = function on(ele, event, handler) {
  if (ele && event && handler) {
    ele.addEventListener(event, handler, false);
  }
};

// export const off = (function() {
//   if (document.removeEventListener) {
//     return function off(ele, event, handler) {
//       if (ele && event) {
//         ele.removeEventListener(event, handler, false)
//       }
//     }
//   } else if (document.detachEvent) {
//     return function off(ele, event, handler) {
//       if (ele && event) {
//         ele.detachEvent('on' + event, handler)
//       }
//     }
//   }
// })()
export const off = function off(ele, event, handler) {
  if (ele && event) {
    ele.removeEventListener(event, handler, false);
  }
};

export const once = function(ele, event, handler) {
  let listener = function() {
    if (handler) {
      handler.apply(this, arguments);
    }
    off(ele, event, listener);
  };
  on(ele, event, listener);
};

export function setTransform(ele, val) {
  ele.style.webkitTransform = val;
  ele.style.MozTransform = val;
  ele.style.msTransform = val;
  ele.style.OTransform = val;
  ele.style.transform = val;
}

export function getTouchEvent(originEvent) {
  return originEvent.changedTouches
    ? originEvent.changedTouches[0]
    : originEvent;
}

export function getComputedStyle(ele) {
  return ele.currentStyle || window.getComputedStyle(ele);
}

export const viewPortDimention = getViewPortDimention();

export function getViewPortDimention() {
  return {
    width: window.innerWidth || document.documentElement.clientWidth,
    height: window.innerHeight || document.documentElement.clientHeight
  };
}

on(window, 'resize', function() {
  Object.assign(viewPortDimention, getViewPortDimention());
});

export function getScrollHeight(element) {
  if (element === window) {
    element = document.documentElement
  }
  return element.scrollHeight
}
export function getVisibleHeight(element) {
  if (element === window) {
    return document.documentElement.clientHeight
  }

  return element.clientHeight
}
