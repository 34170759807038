<template>
  <header class="root">
    <span v-if="backBtn" class="back-btn" @click="handleClickBack"></span
    >Facebook Collections
  </header>
</template>

<script>
export default {
  name: 'MyHeader',
  props: {
    backBtn: Boolean
  },
  methods: {
    handleClickBack() {
      return this.$emit('back');
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/style/func.scss';
.root {
  position: relative;
  font-size: px2vw(35);
  color: #ffffff;
  padding: px2vw(28) 0;
  text-align: center;
  background: #3677ea;
  .back-btn {
    position: absolute;
    left: px2vw(36);
    top: 50%;
    width: px2vw(16);
    height: px2vw(30);
    transform: translateY(-50%);
    background: url('../assets/images/back@2x.png') center center no-repeat;
    background-size: 100% 100%;
  }
}
</style>
