export const logger = {
  log() {
    return console.log.apply(console, arguments);
  },
  error() {
    return console.error.apply(console, arguments);
  },
  warn() {
    return console.warn.apply(console, arguments);
  }
  
};
