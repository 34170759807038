export const NumberUtil = {
  /**
   * 10000
   * @param {Number} num 
   * @returns {String}
   */
  toThousandSplit(num) {
    // var str = '' + num;
    // if (num <= 1000) return str;
    // for(var i = 0; i < str.length; i++) {
    // }  
    // return num.replace(/(\d)(?=(?:d{3})+$)/g, '$1,');
    if (typeof num === 'string') {
      num = +num;
    }
    return num.toLocaleString('en-US');
  },
  /**
   * 获取[min, max)的随机数
   * @param {*} min 
   * @param {*} max 
   * 
   * Math.random [0, 1)
   */
  getRandomNum(min, max) {
    return Math.floor(Math.random() * (max - min) + min)
  }
};