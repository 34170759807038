import Afc from '@/components/AFC';
import VideoCard from '@/components/VideoCard';
// export const TAB_LIST = [
//   {
//     key: 'all', // TODO all的数据顺序是怎么取
//     label: 'all' // TODO 不同的语言应该是需要对应的翻译的
//   },
//   {
//     key: 'game',
//     label: 'game'
//   },
//   {
//     key: 'movie',
//     label: 'movie'
//   },
//   {
//     key: 'music',
//     label: 'music'
//   },
//   {
//     key: 'news',
//     label: 'news'
//   }
// ];

// export const CHANNEL_MAP = TAB_LIST.reduce((sum, item) => {
//   sum[item.key] = item.key;
//   return sum;
// }, {});

export const ACTION_STATES = {
  waiting: 1,
  loading: 2,
  success: 3,
  fail: 4
};

export const FEED_ITEM_TYPE_TO_COMPONENTS = {
  ad: Afc,
  video: VideoCard
};

export const FEED_ITEM_TYPES = {
  ad: 'ad',
  video: 'video'
};

export const TARGET_SITE_URL = 'https://facebook.com';