import { on, off, getScrollTop, getViewPortDimention, getScrollHeight } from './dom';
import { throttle } from './fe-common-utils';
import { logger } from './logger';

/**
 * @param {Object} opt
 * @param {Number} opt.threshold 距离底部的距离小于threshold时触发回调
 * @param {Function} opt.callback 回调
 * @param {Number} opt.throttleInterval 节流函数间隔
 */
export function doWhenScrollToBottom({
  threshold = 50, 
  callback,
  throttleInterval = 300
}) {
  const isToBottom = () => {
    const scrollTop = getScrollTop(window)
    const viewPortHeight = getViewPortDimention().height
    const scrollHeight = getScrollHeight(window)
    const result = scrollHeight - viewPortHeight - scrollTop < threshold
    return result
  }
  const handler = throttle(() => {
    if (isToBottom()) {
      logger.log('isToBottom')
      callback()
      // if (!this.noMore) {
      //   callback()
      // } else {
      //   setTimeout(() => {
      //     callback()
      //   }, 200)
      // }
    } else {
      // logger.log('not isToBottom')
    }
  }, throttleInterval)
  on(window, 'scroll', handler)
  return () => {
    off(window, 'scroll', handler)
  }
}