<template>
  <div class="wrapper" ref="wrapper" :style="wrapperStyle"></div>
</template>

<script>
import { renderAFC, generateFixedSizeAfcAdStr, generateResponsiveAfcAdStr, generateAfcAdStrByParam, logger } from '@/assets/js/util';
export default {
  props: {
    param: Object,
    reponsive: Object,
    fixed: Object
  },
  data() {
    return {
      wrapperStyle: this.fixed ? {
        height: this.fixed.height,
        width: this.fixed.width
      } : {}
    }
  },
  // TODO observer监听ins，然后动态设置背景
  mounted() {
    let adStr = this.param ? generateAfcAdStrByParam(this.param) :
      this.reponsive ? generateResponsiveAfcAdStr(this.reponsive) : 
      this.fixed ? generateFixedSizeAfcAdStr(this.fixed) : '';
    if (adStr) {
      renderAFC(adStr, this.$refs['wrapper']);
    } else {
      logger.error('unexpected error no adStr');
    }
  }
};
</script>

<style lang="scss" scoped>
$bgColor: #eee;
.wrapper {
  // TODO 开发阶段，后面调整
  background: $bgColor;
  height: 327px;
  /deep/ ins {
    display: block;
    width: 100%;
    background: $bgColor;
    height: inherit;
  }
}
</style>
