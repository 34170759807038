<template>
  <div class="home">
    <my-header class="my-header"></my-header>
    <!-- TODO 补充广告代码 -->
    <afc :reponsive="{
      adClient: 'ca-pub-5463545749677968',
      adSlot: '2686949261'
    }"></afc>
    <a
      :href="TARGET_SITE_URL"
      class="go-to-facebook"
    >Go to Facebook</a>
    <div
      class="channels-container"
      v-if="tabList && tabList.length"
    >
      <div
        :class="['tabs', tabFixed ? 'fixed' : '']"
        ref="tabs"
      >
        <ly-tab
          v-model="selectedTabIndex"
          :options="tabOptions"
          :items="tabList"
        ></ly-tab>
      </div>
      <div class="tab-placeholder"></div>
      <div class="channel-list">
        <div class="channel">
          <div
            class="list"
            v-if="currentConfig.list && currentConfig.list.length"
          >
            <feed-item
              v-for="(item, index) in currentConfig.list"
              :type="item.type"
              :key="index"
              :info="item"
              @click="handleFeedItemClick"
              :param="item.params"
            ></feed-item>
            <!-- TODO 处理广告左边空隙 -->
          </div>
          <loading v-if="currentConfig.actionState === ACTION_STATES.loading"></loading>
          <div class="empty-tips" v-if="currentConfig.actionState === ACTION_STATES.success && !currentConfig.list.length">
            No records
          </div>
        </div>
      </div>
    </div>
    <loading v-if="fetchTabsState === ACTION_STATES.loading"></loading>
  </div>
</template>

<script>
import MyHeader from '@/components/Header.vue';
// @ is an alias to /src
import AFC from '@/components/AFC.vue';
import LyTab from '../../node_modules/ly-tab/src/index';
import { ACTION_STATES, FEED_ITEM_TYPES, TARGET_SITE_URL } from '@/assets/js/constants.js';
import {
  API,
  logger,
  throttle,
  persistLayer,
  getYoutubeVideoId,
  doWhenScrollToBottom
} from '@/assets/js/util.js';
import { getElementOffset, getScrollTop } from '@/assets/js/dom.js';
// import VideoItem from '@/components/VideoCard.vue';
import Loading from '@/components/Loading.vue';
// import Tabs from '@/components/vue-swipe-tab/lib/index.vue';
// import Tab from '@/components/vue-swipe-tab/lib/tab.vue';
import FeedItem from '@/components/feed-item.vue';

export default {
  // 注意Home路由会keep-alive缓存状态
  name: 'Home',
  data() {
    // const initialIndex = 0;
    return {
      countryCode: 'US', // TODO从url中解析
      selectedTabIndex: -1, // 初始值无意义
      tabList: [],
      tabOptions: {
        activeColor: '#3677EA',
        labelKey: 'name',
      },
      channelListMap: {},
      ACTION_STATES,
      tabFixed: false,
      cachedTabOffset: {},
      cachedScrollTop: 0,
      fetchTabsState: ACTION_STATES.waiting,
      TARGET_SITE_URL,
    };
  },
  computed: {
    tabListConfigs() {
      return Object.values(this.channelListMap);
    },
    currentConfig() {
      return this.tabListConfigs[this.selectedTabIndex];
    },
    currentChannelKey() {
      return this.currentConfig.key;
    }
  },
  watch: {
    selectedTabIndex: {
      handler(val) {
        const curIndex = val;
        const curConfig = this.channelListMap[this.tabList[curIndex].key];
        if (!curConfig) {
          return logger.error('no curConfig', curIndex, this.channelListMap);
        }
        if (curConfig.actionState === ACTION_STATES.waiting) {
          this.loadTabContent(curIndex);
        }
        // 不需要加载下个tab
        // if (curIndex > -1 && curIndex < this.tabList.length - 1) {
        //   this.loadTabContent(curIndex + 1);
        // }
      },
    },
  },
  created() {
    this.fetchTabList(this.countryCode);
    this.throttledAdjustTabPosition = throttle(this.adjustTabPosition, 200);
    window.addEventListener('scroll', this.throttledAdjustTabPosition);
  },
  mounted() {

  },
  activated() {
    // 初次渲染、从详情页返回时会执行
    logger.log('activated')

    this.removeLoadMoreListener = doWhenScrollToBottom({
      callback: () => {
        this.loadMore();
      }
    })

    if (this.cachedScrollTop > 0) {
      window.scrollTo(0, this.cachedScrollTop);
      this.cachedScrollTop = 0;
    }
  },
  deactivated() {
    // 跳转详情页时会执行
    logger.log('deactivated')
    this.removeLoadMoreListener && this.removeLoadMoreListener();
  },
  unmounted() {
    window.removeEventListener('scroll', this.throttledAdjustTabPosition);
    this.removeLoadMoreListener && this.removeLoadMoreListener();
  },
  methods: {
    handleFeedItemClick() {
      this.cachedScrollTop = getScrollTop();
      logger.log('handleFeedItemClick', this.cachedScrollTop)
    },
    adjustTabPosition() {
      // console.log('adjustTabPosition', Date.now())
      const tabs = this.$refs.tabs;
      if (!tabs) return;
      const scrollTop = getScrollTop();
      const offset = getElementOffset(tabs);

      if (this.tabFixed) {
        // 出现过一次this.cachedTabOffset.top 为负，导致始终不能通过判断
        if (scrollTop < this.cachedTabOffset.top || scrollTop <= 50) {
          logger.log('remove fixed: scrollTop, this.cachedTabOffset.top, scrollTop', scrollTop, this.cachedTabOffset.top, scrollTop)
          this.tabFixed = false;
        }
        return;
      } else if (scrollTop > offset.top) {
        // 滚出视口
        logger.log(
          'adjustTabPosition: scrollTop, offset.top',
          scrollTop,
          offset.top
        );
        this.tabFixed = true;
        this.cachedTabOffset = offset;
      }
    },

    fetchTabList(countryCode) {
      this.fetchTabsState = ACTION_STATES.loading;
      return API.queryFeedTabs(countryCode).then(({ data }) => {
        this.fetchTabsState = ACTION_STATES.success;
        const { categories } = data;
        this.tabList = categories || [];
        this.initChannelListMap(this.tabList);
        this.selectedTabIndex = 0; // 初始化选择第一个tab
      }).catch(err => {
        logger.error(err);
        this.fetchTabsState = ACTION_STATES.fail;
      });
    },
    initChannelListMap(tabList) {
      this.channelListMap = tabList.reduce((sum, item) => {
        sum[item.key] = {
          list: [],
          next: '',
          key: item.key,
          actionState: ACTION_STATES.waiting,
        };
        return sum;
      }, {});
    },
    // changeFunc() {},
    fetchList(channel) {
      const config = this.channelListMap[channel];
      if (config) {
        if (config.actionState === ACTION_STATES.loading) {
          logger.log('cancal fetchList for is already loading', config.key)
          return;
        }
        Object.assign(config, {
          actionState: ACTION_STATES.loading,
        });
      } else {
        return logger.error('unexpected error', channel, this.channelListMap);
      }
      logger.log('fetchList', channel);
      return API.queryFeedList(channel, {
        country: this.countryCode,
        next: config.next
      })
        .then(({ data }) => {
          const { items, next } = data;
          logger.log('queryFeedList success', items, next)
          Object.assign(config, {
            next,
            actionState: ACTION_STATES.success,
          });
          config.list.push(...this.preprocessData(items || []));
          persistLayer.saveFeedItems(
            config.list.filter((item) => item.type !== FEED_ITEM_TYPES.ad),
            'id'
          );
        })
        .catch((err) => {
          // TODO handle error
          logger.error(err);
          Object.assign({
            actionState: ACTION_STATES.fail
          })
        });
    },
    preprocessData(items = []) {
      return items.map((item) => {
        if (item.type === FEED_ITEM_TYPES.video) {
          if (('' + item.timestamp).length === 10) {
            // 返回的时间格式是时间戳，单位是秒，处理成前端需要的毫秒单位
            item.timestamp = item.timestamp * 1000;
          }
          item.id = getYoutubeVideoId(item.origin);
        }
        return item;
      });
    },
    loadTabContent(index) {
      if (index >= 0 && index < this.tabList.length) {
        this.fetchList(this.tabList[index].key);
      }
    },
    loadMore() {
      logger.log('loadMore', this.currentChannelKey)
      this.fetchList(this.currentChannelKey);
    }
  },
  components: {
    MyHeader,
    Loading,
    LyTab,
    FeedItem,
    Afc: AFC,
    // Tabs,
    // TabPanel: Tab
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/style/func.scss';
.home {
  .my-header {
    margin-bottom: px2vw(23);
  }
  .go-to-facebook {
    display: block;
    width: px2vw(720);
    margin: px2vw(29) auto px2vw(36);
    background: #3677ea;
    border-radius: px2vw(14);
    font-size: px2vw(30);
    padding: px2vw(27) 0;
    text-align: center;
    text-decoration: none;
    color: #fff;
  }
  .channels-container {
    background: #fff;
    .tabs {
      min-width: 100%;
      background: inherit;
      position: relative;
      z-index: 9999;
      &.fixed {
        position: fixed;
        left: 0;
        top: 0;
        & + .tab-placeholder {
          display: block;
        }
      }
    }
    .tab-placeholder {
      height: px2vw(86);
      display: none;
    }
    .channel-list {
      box-sizing: border-box;
      min-height: 100vh;
    }
    .empty-tips {
      text-align: center;
      padding: 2em 0;
    }
  }
  /deep/ .cube-tab-bar {
    border: 1px solid rgba(238, 240, 248, 1);
  }
  /deep/ .cube-tab.cube-tab_active {
    color: #3677ea;
  }
  /deep/ .cube-tab {
    padding: px2vw(25) 0;
  }
  /deep/ .video-card {
    border-bottom: 1px solid #eee;
  }

  .first {
    height: 1000px;
    background: red;
  }
  .second {
    height: 1500px;
    background: blue;
  }
  .three {
    height: 800px;
    background: yellow;
  }
}
</style>
