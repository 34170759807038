const patterns = [[/\/en\//, 'US']];
const pathname = window.location.pathname;
const match = patterns.find(item => pathname.match(item[0]))
const countryCode = match ? match[1] : 'US';

export const Env = {
  isDev() {
    return process.env.NODE_ENV === 'development';
  },
  // https://facebook.lovetinygame.com/en/index.html
  // https://facebook.lovetinygame.com/test/en/index.html
  getCountryCode() {
    return countryCode;
  } 
};
