import Vue from 'vue';
import App from './App.vue';
import router from './router';

import 'reset-css';
import '@/assets/style/common.scss';
// import Tab from './components/vue-swipe-tab';
// Vue.use(Tab);

Vue.config.productionTip = false;

new Vue({
  router,
  render: h => h(App)
}).$mount('#app');
