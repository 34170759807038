
import { NumberUtil } from './number-util';
import { logger } from './logger';
/**
 * 从原youtube 播放地址中解析videoId
 * @param {*} watchUrl https://www.youtube.com/watch?v=gmRKv7n2If8
 */
export function getYoutubeVideoId(watchUrl = '') {
  const key = (watchUrl.match(/\/watch\?v=(.*)/) || [])[1];
  if (!key) {
    logger.error('getEmbededVideoUrl error', watchUrl);
  }
  return key;
}

export function getYoutubeEnbedUrl(videoId) {
  return `https://www.youtube.com/embed/${videoId}`;
}

/**
 * 
 * @param {Array} list 
 * @param {number} count 
 */
export function getRandomItems(list, count) {
  if (list.length <= count) return list;
  const idxs = list.map((_, index) => index);
  const randoms = []
  while(randoms.length < count) {
    const i = NumberUtil.getRandomNum(0, idxs.length);
    randoms.push(idxs[i]);
    idxs.splice(i, 1)
  }
  return randoms.map(i => list[i]);
}
