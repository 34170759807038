/**
 * data-ad-format="fluid" 类型的广告好像是不固定尺寸的，可以试试代替responsive？
 */

/**
 * 
 * @param {*} adStr 
 * @param {*} parentDom 
 */

export function renderAFC(adStr, parentDom) {
  parentDom.innerHTML = adStr;
  var script = document.createElement('script');
  script.innerHTML = '(adsbygoogle = window.adsbygoogle || []).push({});';
  parentDom.appendChild(script);
}

export function generateFixedSizeAfcAdStr({ width, height, adClient, adSlot }) {
  return `'<ins class="adsbygoogle"
  style="display:inline-block;width:${width};height:${height}"
  data-ad-client="${adClient}"
  data-ad-slot="${adSlot}"></ins>'`;
}

export function generateResponsiveAfcAdStr({adClient, adSlot}) {
  return `<ins class="adsbygoogle"
  style="display:block"
  data-ad-client="${adClient}"
  data-ad-slot="${adSlot}"
  data-ad-format="auto"
  data-full-width-responsive="true"></ins>`;
}

export function generateAfcAdStrByParam(param = {}) {
  const props = Object.keys(param).reduce(function(sum, key) {
    sum += ` data-${key}=${param[key]} `;
    return sum;
  }, '')
  return `<ins class="adsbygoogle" ${props}></ins>`
}

// '<ins class="adsbygoogle"
// style="display:block"
// data-ad-client="ca-pub-1928325159523008"
// data-ad-slot="5628316761"
// data-ad-format="auto"
// data-full-width-responsive="true"></ins>';

// '<ins class="adsbygoogle" \
// style="display:inline-block;width:320px;height:50px" \
// data-ad-client="ca-pub-7621450473269765" \
// data-ad-slot="6511505637"></ins>';

// "params": {
//   "ad-format": "fluid",
//   "ad-client": "ca-pub-1234567891234567",
//   "ad-slot": "1234567890"
// }